<template>
  <div class="TestKitList">
    <div class="form">
      <el-form
        ref="formRef"
        :inline="true"
        label-width="100px"
        :model="form"
        @submit.native.prevent
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="患者昵称" prop="username">
              <el-input
                v-model="form.username"
                clearable
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                clearable
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管理医生" prop="identify">
              <el-select v-model="form.identify" clearable placeholder="请选择">
                <el-option
                  v-for="item in doctor_list"
                  :key="item.id"
                  :label="item.docName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="患者状态" prop="patientStatus">
              <el-select
                v-model="form.patientStatus"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in patient_status"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item class="TestKitList-form-button">
              <el-button
                @click="onSubmit(form)"
                type="primary"
                native-type="submit"
                >查询</el-button
              >
              <el-button @click="Reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div v-loading="this.loading" class="list">
      <div class="TestKitList-list-handle">
        <div></div>
        <div class="but">
          <el-button type="primary" @click="openListFn" size="small"
            >展开待回复</el-button
          >
        </div>
      </div>
      <el-table
        :data="table"
        v-loading="tableLoad"
        row-key="bowlId"
        :expand-row-keys="expand_row_keys"
        @expand-change="expand_change"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.list">
              <el-table-column
                prop="createTime"
                align="center"
                label="上传时间"
              >
                <template #default="scope">
                  <div class="tip-box">
                    <p style="margin-right: 6px;">{{ scope.row.createTime }}</p>
                    <div
                      class="tip"
                      :class="{
                        success:
                          scope.row.status === '1' || scope.row.status === '3',
                        fail: scope.row.status === '0',
                        confirm: scope.row.status === '2',
                      }"
                    ></div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="replyTime" align="center" label="回复时间">
              </el-table-column>
              <el-table-column
                prop="replyComment"
                align="center"
                label="回复内容"
              >
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <template v-if="scope.row.status === '0'">
                    <el-button type="text" size="small" @click="see(scope.row)"
                      >回复</el-button
                    >
                  </template>
                  <template
                    v-else-if="
                      scope.row.status === '1' || scope.row.status === '3'
                    "
                  >
                    <el-button type="text" size="small" @click="see(scope.row)"
                      >查看</el-button
                    >
                  </template>
                  <template v-else-if="scope.row.status === '2'">
                    <el-button
                      type="text"
                      size="small"
                      @click="see(scope.row)"
                      style="color:rgb(245, 145, 35);"
                      >确认</el-button
                    >
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" align="center" label="患者昵称">
          <!--          <template slot-scope="scope">-->
          <!--            <span class="TestKitList-list-blue" @click="detail(scope.row)" >{{ scope.row.patientName }}</span>-->
          <!--          </template>-->
        </el-table-column>
        <el-table-column prop="patientName" align="center" label="患者姓名">
        </el-table-column>
        <el-table-column prop="gender" align="center" label="患者性别">
          <template #default="scope">
            <span>{{ gender_name(scope.row.gender) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" align="center" label="患者年龄">
        </el-table-column>
        <el-table-column prop="phoneNo" align="center" label="手机号">
        </el-table-column>
        <el-table-column prop="docName" align="center" label="管理医生">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template #default="scope">
            <div class="tip-box">
              <div
                class="tip"
                :class="{
                  success: scope.row.status === '1',
                  fail: scope.row.status === '0',
                }"
              ></div>
              <p style="margin-left: 6px;">
                {{ status_name(scope.row.status) }}
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <BowelDialog
      v-model="bowel_data.show"
      :bowlId="bowel_data.bowlId"
      :defecatId="bowel_data.defecatId"
      @close="closeFn"
    />
  </div>
</template>

<script>
import BowelDialog from "@/components/TestKit/dialog/BowelDialog";
export default {
  name: "bowelPreparation",
  components: {
    BowelDialog,
  },
  data() {
    return {
      loading:false,

      form: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        phone: "",
        identify: "",
        patientStatus: "",
      },
      total: 0,
      doctor_list: [],
      table: [],
      tableLoad: false,
      range: [
        {
          value: "1",
          text: "男",
        },
        {
          value: "0",
          text: "女",
        },
      ],
      report_list: [],
      patient_status: [
        {
          key: "0",
          value: "待回复",
        },
        {
          key: "1",
          value: "已完成",
        },
      ],

      bowel_data: {
        show: false,
        bowlId: "", // 肠道准备Id
        defecatId: "", // 排便Id
      },
      expand_row_keys: [],
    };
  },
  computed: {
    gender_name() {
      return function(row) {
        let str;
        str = this.range
          .filter((item) => item.value === row)
          .map((item) => item.text)[0];
        return str;
      };
    },
    status_name() {
      return function(row) {
        let str;
        str = this.patient_status
          .filter((item) => item.key === row)
          .map((item) => item.value)[0];
        return str;
      };
    },
  },
  created() {
    this.get_list();
    this.doctorListFn();
  },
  mounted() {},
  methods: {
    closeFn() {
      this.get_list();
    },
    see(row) {
      console.log(row);

      this.bowel_data.bowlId = row.bowlId;
      this.bowel_data.defecatId = row.defecatId;
      this.bowel_data.show = true;
    },
    openListFn() {
      this.expand_row_keys = (this.table || [])
        .filter((item) => item.status === "0")
        .map((item) => item.bowlId);
      console.log(this.expand_row_keys);
    },
    expand_change(e, rows) {
      this.expand_row_keys = (rows || []).map((item) => item.bowlId);
    },
    onSubmit(y) {
      y.pageNum = 1;
      this.get_list(y);
    },
    Reset() {
      this.$refs["formRef"].resetFields();
      this.$nextTick(() => {
        this.get_list();
      });
    },
    get_list() {
      this.loading = true;
      this.$get("colonreply/list", this.form).then((res) => {
        if (res) {
          console.log(res);
          let data_ = res.data;
          this.total = data_.total;
          this.table = data_.list;
          this.loading = false;
        }
      });
    },
    handleSizeChange(e) {
      this.form.pageSize = e;
      this.get_list();
    },
    handleCurrentChange(e) {
      this.form.pageNum = e;
      this.get_list();
    },
    doctorListFn() {
      this.$get("colondoc/doctorlisrt").then((res) => {
        if (res) {
          console.log(res);
          let data_ = res.data;
          this.doctor_list = data_;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/TestKit/TestKitList";

$TipWidthHeight: 7px;

.tip-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .tip {
    width: $TipWidthHeight;
    height: $TipWidthHeight;
    border-radius: 50%;
  }

  .success {
    background: #63a103;
  }

  .fail {
    background: #d9001b;
  }

  .confirm {
    background: rgb(245, 145, 35);
  }
}
</style>
